import { createRouter, createWebHashHistory } from "vue-router";

const routes = [
	{
		path: "/",
		name: "Home",
		component: () => import(/* webpackChunkName: "home" */ "../pages/index.vue"),
	},
	{
		path: "/config",
		name: "Config",
		component: () => import(/* webpackChunkName: "config" */ "../pages/config.vue"),
	},
	{
		path: "/sftp",
		name: "Sftp",
		component: () => import(/* webpackChunkName: "sftp" */ "../pages/sftp.vue"),
	},
	{
		path: "/help",
		name: "Help",
		component: () => import(/* webpackChunkName: "help" */ "../pages/help.vue"),
	},
	{
		path: "/editor",
		name: "Editor",
		component: () => import(/* webpackChunkName: "editor" */ "../pages/editor.vue"),
	},
];

const router = createRouter({
	history: createWebHashHistory(),
	routes,
});

export default router;
