
import { createApp } from "vue";
import App from "@/App.vue";
import "@/index.scss";
import router from "./router/router";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(faPhone);

const app = createApp(App);

app
	.config
	.delimiters = ["{%", "%"];
app
	.component("font-awesome-icon", FontAwesomeIcon)
	.use(router)
	.provide("progressBar", app.config.globalProperties.$setup)
	.mount("#app");
