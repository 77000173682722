<template>
  <meta charset="utf-8">
	<div class="navigation">
		<ul class="nav flex-column">
		<li class="nav-item">
			<router-link class="nav-link" to="/">
			<img class="logo" src="./assets/images/logo.svg" />
			</router-link>
		</li>
		<li class="nav-item">
			<router-link class="nav-link" to="/editor">
			<i class="far fa-file"></i>
			</router-link>
		</li>
		<li class="nav-item">
			<router-link class="nav-link" to="/help">
			<i class="far fa-question-circle"></i>
			</router-link>
		</li>
		<li class="nav-item">
			<router-link class="nav-link" to="/sftp">
			<i class="far fa-share-square"></i>
			</router-link>
		</li>
		<li class="nav-item">
			<router-link class="nav-link" to="/config">
			<i class="fas fa-cog"></i>
			</router-link>
		</li>
		</ul>
	</div>
	<div id="view-container">
		<router-view />
	</div>
</template>

<script>
import { ref, provide } from "vue";

export default {
	setup() {
		/* const targetHandler = {
			get(target, property) {
				console.log("intercepted");
				return target[property];
			}
		}; */
		const dbUrl = "http://localhost:3000/";
		const mailsystems = ref([]);
		const trackinglinks = ref([]);
		const data = ref({});
		//const mailsystemPaths = new Proxy(mailsystems, targetHandler);
		const distributorList = ref([]);

		
		provide("$dbUrl", dbUrl);
		provide("$mailsystems", mailsystems);
		provide("$distributorList", distributorList);

		return {
			trackinglinks,
			data
		};
	}
};
</script>

<style lang="scss">
/* stylelint-disable indentation */
@import "./src/assets/styles/color.scss";

body {
	background-color: $background;
  animation: switchColor 5s;
}

@keyframes switchColor {
  0% {
    background: $tiles;
  }
    100% {
    background: $background;
  }
}


#app {
	font-family: Avenir, Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-align: center;
	color: #000000;
}

.navigation {
	width: 60px;
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	background-color: #4b3030;
	border-right: 4px solid $confirm;
  animation: switchColorNav 5s;

  @keyframes switchColorNav {
      0% {
            background: $tiles;
          }
      100% {
            background: #4b3030;
          }
    }

  ul {
		list-style: none;
		height: 100%;
	}
	li {
		width: 56px;
		&:first-child {
			margin-bottom: 24px;
		}
		a {
			text-align: center;
			font-size: 2rem;
			i {
				color: $background;
				font-weight: lighter;
				font-size: 24px;
			}
			.logo {
				bottom: -3px;
				height: 30px;
				width: 30px;
				display: inline-block;
				position: relative;
				transform: scale(1.25);
			}
		}
		.router-link-active {
			background-color: $active;
		}
	}
}
</style>
